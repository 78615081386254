<template>
    <content-view>
        <div class="results">
            <router-link tag="a" :to="`/courses/${$route.params.course_id}/`" class="results__link-back">
                <svg
                    width="9"
                    height="13"
                    viewBox="0 0 9 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.54597 12.0919L2 6.54597L7.54597 1"
                        stroke="#A4AEBD"
                        stroke-width="2"/>
                </svg>
                <span>Назад</span>
            </router-link>
            <h1 class="results__title">Результаты курса</h1>

            <div class="results__body">
                <div class="results__table">
                    <div class="table__body">
                        <div class="table__row table__row--header">
                            <div class="table__cell">
                                <div class="cell__filter" @click="filterFio">
                                    ФИО
                                    <span
                                        class="filter__icon"
                                        :class="{
                                        'filter__icon--desc': filter.fio === 'up',
                                        'filter__icon--asc': filter.fio === 'down',
                                    }"></span>
                                </div>
                            </div>
                            <div class="table__cell">
                                <div class="cell__filter" @click="filterStatus">
                                    Статус
                                    <span
                                        class="filter__icon"
                                        :class="{
                                        'filter__icon--desc': filter.status === 'up',
                                        'filter__icon--asc': filter.status === 'down',
                                    }"></span>
                                </div>
                            </div>
                            <div class="table__cell" v-for="module in modules" :key="`module_table_${module.id}`">
                                {{ module.name }}
                            </div>
                        </div>

                        <result
                            v-for="(result, index) in results"
                            :key="`result_${result.id}`"
                            :is-result="result"
                            :is-id-course="$route.params.course_id"
                            @delete="deleteResult"
                        ></result>
                    </div>
                    <loading-spinner
                        v-if="isLoading"
                        class="wall__spinner"
                    />
                    <mugen-scroll
                        :handler="infiniteScrollHandler"
                        :should-handle="!isLoading"
                    />
                </div>
            </div>
        </div>
    </content-view>
</template>

<script>
import session from "@/api/session";
import MugenScroll from 'vue-mugen-scroll';

export default {
    name: 'CourseResults',
    components: {
        Result : () => import('@/components/course/results/Result'),
        MugenScroll,
        LoadingSpinner : () => import('@/components/LoadingSpinner'),
        // CommentsSubmenu,
        ContentView : () => import('@/views/menu/ContentView'),
        Icon : () => import('@/components/icon/Icon')
    },
    data() {
        return {
            isLoading: false,
            results: [],
            nextData: `/api/v1/course/${this.$route.params.course_id}/results/`,
            modules: [],
            filter: {
                fio: null,
                status: null
            }
        }
    },
    async created () {
        await this.defaultData()
        await this.defaultList()
    },
    methods: {
        async filterFio() {
            if (this.filter.fio === null) {
                this.filter.fio = 'up'
            } else if (this.filter.fio === 'up') {
                this.filter.fio = 'down'
            } else if (this.filter.fio === 'down') {
                this.filter.fio = null
            }
            this.filter.status = null;
            await this.defaultList();
        },
        async filterStatus() {
            if (this.filter.status === null) {
                this.filter.status = 'up'
            } else if (this.filter.status === 'up') {
                this.filter.status = 'down'
            } else if (this.filter.status === 'down') {
                this.filter.status = null
            }
            this.filter.fio = null;
            await this.defaultList();
        },
        async defaultData() {
            await session
                .get(`/api/v1/course/${this.$route.params.course_id}/default_results/`)
                .then(response => {
                    let data = response.data;
                    this.modules = data.modules;
                });
        },
        async defaultList() {
            this.results = [];
            this.nextData = `/api/v1/course/${this.$route.params.course_id}/results/`;
            this.isLoading = true
            this.results = await this.loadList();
            this.isLoading = false
        },
        async loadList() {
            let data, response;
            try {
                let params = {
                    fio: this.filter.fio ? this.filter.fio : null,
                    status: this.filter.status ? this.filter.status : null
                };
                const request = session.get(this.nextData, {params});
                response = await request;
                data = response.data.results;
                this.nextData = response.data.next;
                return data
            } catch (err) {
                console.error('err', err)
            }
        },
        async infiniteScrollHandler() {
            if (this.results.length > 0) {
                if (!this.nextData) {
                    return false;
                }
                this.isLoading = true;
                let data = await this.loadList();
                this.results.push(...data);
                this.isLoading = false;
            }
        },
        deleteResult(idResult) {
            this.results = this.results.filter((variable) => idResult !== variable.user.id);
        }
    }
}
</script>

<style lang="scss">
// @import "list";
@import "#sass/v-style";

.results {
    width: 100%;
    padding: 32px 40px 40px;
    color: #000;
    font-style: normal;

    @include below($mobile) {
        padding: 32px 16px;
    }

    &__link-back {
        display: flex;
        align-items: center;
        width: 80px;
        margin-bottom: 30px;
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;

        color: $text-gray;

        svg {
            margin-right: 10px;
        }

        span {
            @include below($tablet) {
                display: none;
            }
        }

        &:hover,
        &:focus {
            color: $black;

            svg path {
                stroke: $black;
            }
        }

        @include below($mobile) {
            margin-bottom: 22px;
        }
    }

    &__title {
        margin-bottom: 30px;
        font-size: 20px;
        line-height: 26px;
        font-weight: 600;

        @include below($mobile) {
            margin-bottom: 26px;
            font-size: 18px;
        }
    }

    &__body {
        position: relative;
        background: #fff;

        @include below($mobile) {
            padding: 0;
        }
    }

    &__table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;

        @include below($mobile) {
            display: flex;
            flex-direction: column;
        }

        .table__body {
            display: table-row-group;
        }

        .table__row {
            display: table-row;
            position: relative;
            @include below($mobile) {
                padding-top: 20px;
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
            }

            &--header {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                color: $text-gray;

                @include below($mobile) {
                    display: none;
                }

                .table__cell {
                    height: auto;
                    padding-top: 0;
                    padding-bottom: 20px;
                }
            }

            &--body {
                &:after {
                    content: "";
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    background: #e6ebf3;
                    left: 0;
                    @include below($mobile) {
                        content: none;
                    }
                }
            }
        }

        .table__cell {
            display: table-cell;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            padding-top: 20px;
            padding-bottom: 20px;
            position: relative;
            padding-right: 48px;

            @include below($mobile) {
                height: auto;
                display: flex;
                font-size: 14px;
                line-height: 16px;
                padding-top: 0;
                padding-bottom: 28px;
                padding-right: 0;
                flex-direction: column;
            }

            &--status {
                font-size: 14px;
                line-height: 20px;

                @include below($mobile) {
                    margin-left: 73px;
                    margin-top: -25px;
                }
            }

            &--module {
                @include below($mobile) {
                    flex-direction: row;
                    margin-left: 73px;
                }
            }
        }

        .mobile-header {
            display: none;
            font-weight: normal;
            font-size: 14px;
            line-height: 32px;
            color: $text-gray;
            @include below($mobile) {
                display: flex;
                position: absolute;
                left: -73px;
            }
        }

        .module__results {
            display: inline-flex;

            span {
                padding: 2px 13px;
                border-radius: 4px;
                margin-right: 12px;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
            }

            &--lessons {
                .lesson-passed {
                    color: #50b440;
                    border: 2px solid #c2edbc;
                }

                .lesson-set {
                    background: #e6ebf3;
                    color: $black;
                }
            }

            &--tests {
                span {
                    padding: 4px 13px;
                }

                .test-passed {
                    color: $white;
                    background: #50b440;
                }

                .test-failed {
                    background: #e32b2b;
                    color: $white;
                }
            }
        }

        .cell__filter {
            display: flex;
            margin-right: 10px;
            align-items: center;
            cursor: pointer;

            span {
                margin-left: 3px;
            }

            .filter__icon {
                @include icon($filter-icon-common, 8px, 11px);

                &--desc {
                    @include icon($filter-icon-desc, 8px, 11px);
                }

                &--asc {
                    @include icon($filter-icon-asc, 8px, 11px);
                }
            }
        }

        .cell__inner {
            display: flex;
            align-items: center;

            &-avatar {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                border: 5px solid $white;
                box-shadow: 0 3px 10px rgba(0, 0, 0, 0.07);

                @include below($mobile) {
                    width: 53px;
                    border: 3px solid $white;
                }

                img {
                    width: 100%;
                    border-radius: 50%;
                }
            }

            &-text {
                display: flex;
                flex-direction: column;
                margin-left: 20px;
            }

            &-status {
                margin-right: 12px;
            }
        }

        .cell__title {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
        }

        .cell__position {
            color: $text-gray;
            font-size: 14px;
            line-height: 20px;
        }

        .desktop-group {
            justify-content: flex-start;
        }
    }
}
</style>
